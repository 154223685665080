import {
  CrmConnection,
  CrmOnboardingStep,
  GongConnection,
  GoogleCalendarConnection,
  OutlookCalendarConnection,
  RecallBotConfig,
  RecallCalendarConnection,
  RecallCalendarEvent,
  RecallZoomConnection,
  SalesloftConnection,
  SlackConnection,
  TeamsConnection,
} from "./UserData"
import { FieldIdentifier } from "./crm"

// USERS AND USER MANAGEMENT

// Note that this is separate from the firebase/auth.User but the UserData.id should always match
// the firebase/auth.User.uid
// The exception in naming is due to User being too easy to confuse to firebase User model.

// collection: "users"
export interface UserData {
  id: string // firebase generated uid
  email: string // for quality of life, makes users easy to search and recognize
  userOnboarding?: string
  crmOnboarding?: CrmOnboardingStep
  calendarConnection?: GoogleCalendarConnection | OutlookCalendarConnection
  slackConnection?: SlackConnection
  crmConnection?: CrmConnection
  gongConnection?: GongConnection
  salesloftConnection?: SalesloftConnection
  recallCalendarConnection?: RecallCalendarConnection
  recallCalendarEvents?: Array<RecallCalendarEvent>
  recallZoomConnection?: RecallZoomConnection | null
  recallBotConfig?: RecallBotConfig | null
  orgRef: Org["id"]
  teamRefs?: Team["id"][]
  favouriteTemplates?: SummaryTemplate["id"][]
  teamsConnection?: TeamsConnection | null
  botJoinOption?: BotJoinOption
}

export enum BotJoinOption {
  ALL_MEETINGS = "ALL_MEETINGS",
  INTERNAL_MEETINGS_ONLY = "INTERNAL_MEETINGS_ONLY",
  EXTERNAL_MEETINGS_ONLY = "EXTERNAL_MEETINGS_ONLY",
  NONE = "NONE",
}

// collection: "teams"
export interface Team {
  id: string // use orgRef_uuid format
  orgRef: Org["id"]
  adminRefs: UserData["id"][]
}

// collection: "orgs"
export interface Org {
  id: string // use the company domain
  members: OrgMember[]
  memberRefs: string[] // exists as a separate field because of Firestore rule limitations
  adminRefs: UserData["id"][]
  invitedUsers?: InvitedUser[]
}

export interface OrgMember {
  id: UserData["id"]
  email: UserData["email"]
  license: License
}

export interface InvitedUser {
  email: string
  role: string
  createdAt: number
  orgRef: Org["id"]
  invitedByUserRef: UserData["id"]
  invitedByUserEmail: UserData["email"]
}

export type License = "free" | "pro" | "xl"

// MEETINGS, TRANSCRIPTS AND SUMMARIES

// collection: "meetings"
export interface Meeting {
  id: string
  dealRefs?: Deal["id"][]
  accountRefs?: Account["id"][]
  displaySummaryRef?: string // legacy, delete in next migration
  name: string
  ownerRefs: UserData["id"][]
  ISO6391LangCode?: LanguageCode | RecallLanguageCode
  participants: Participant[]
  createdAtTimestamp?: number
  startTime: number
  endTime?: number
  source?: string
  externalId?: string
  summary?: SummaryBlock[]
  coachingMetrics?: CoachingMetrics
  crmNoteId?: string
  crmLinkedAccountToNoteId?: string
  crmLinkedDealToNoteId?: string
  crmAccountNoteId?: string
  crmDealNoteId?: string
  crmShouldSyncNote?: boolean
  talkRatio?: number
  meetingUrl: string | ""
  calendarProvider: string | ""
  meetingPlatform: string | ""
  botId?: string | undefined
  isBotDisabled?: boolean
  enrich?: MeetingEnrich
  rawId?: string
  updatedAt?: number
  followupEmails?: FollowupEmail[]
  productRef?: Product["id"]
}

export interface FollowupEmail {
  type: FollowUpEmailType
  body: string
}

export enum FollowUpEmailType {
  LONG_EMAIL = "LONG_EMAIL",
  SHORT_EMAIL = "SHORT_EMAIL",
}

export interface MeetingEnrich {
  companyLookup: {
    companyName: string
    companyDomain: string
    enrichRef: string
  }
  personLookups: { email: string; enrichRef: string }[]
}

export interface Participant {
  name: string
  email?: string
  type: ParticipantType
  contactRef?: Contact["id"]
}

export enum ParticipantType {
  Internal = "internal",
  External = "external",
}

export type MeetingWithDialogue = Meeting & {
  dialogue: DialogueEntry[]
}

export type LanguageCode =
  | "en"
  | "es-mx"
  | "es"
  | "pt-br"
  | "fr"
  | "de"
  | "it"
  | "nl"
  | "ja"
  | "ru"
  | "ko"
  | "pt"
  | "th"
  | "tr"
  | "pl"
  | "ro"
  | "id"
  | "vi"
  | "fr-ca"

export interface DialogueEntry {
  speaker: string
  speakerFirstName: string
  utterance: string
  timestampString: string
}

export enum GoogleEmbeddingModel {
  GECKO_1 = "textembedding-gecko@001",
}

// collection: "transcripts"
export interface Transcript {
  id: string // Must equal meeting.id
  dialogue: DialogueEntry[]
  createdAt?: string | number // use Date.now(), string allowed for legacy
  updatedAt?: string | number // use Date.now(), string allowed for legacy
  googleEmbeddings?: {
    model: GoogleEmbeddingModel
    embeddingsIds: string[]
  }
}

// collection: prepTemplates

export interface PrepTemplate {
  id: string
  description: string
  instructions?: string
  fields: PrepTemplateField[]
  name: string
  ownerRefs: UserData["id"][]
  teamRefs: Team["id"][]
  changeLogs?: TemplateChangeLog[]
}

export interface PrepTemplateField {
  name: string
  question: string
}

export const DefaultPrepField: PrepTemplateField = {
  name: "",
  question: "",
}

export interface TemplateChangeLog {
  userId: string
  updatedAt: number
}

// collection: crmTemplates
export interface CrmTemplate {
  id: string
  description: string
  instructions?: string
  crmFields: CrmTemplateField[]
  name: string
  ownerRefs: UserData["id"][]
  teamRefs: Team["id"][]
  changeLogs?: TemplateChangeLog[]
}

export interface CrmTemplateField {
  name: string
  prompt: string
  field: FieldIdentifier
  updateMode: "overwrite" | "append"
  speakersToSearch: SpeakersToSearch
}

export const DefaultCrmField: CrmTemplateField = {
  name: "",
  prompt: "",
  field: "Account.Name",
  updateMode: "overwrite",
  speakersToSearch: "all",
}

// collection: summaryTemplates
export interface SummaryTemplate {
  id: string
  description: string
  instructions?: string
  fields: SummaryTemplateField[]
  name: string
  ownerRefs: UserData["id"][]
  teamRefs: Team["id"][]
  changeLogs?: TemplateChangeLog[]
}

export type BlockType = "bullet" | "actionItems"
export type SectionToSearch = "all" | "last 1000 words"
export type SpeakersToSearch = "all" | "customer only" | "sales persons only"

export interface SummaryTemplateField {
  name: string
  question: string
  blockType: BlockType
  sectionToSearch: SectionToSearch
  speakersToSearch: SpeakersToSearch
}

export interface SummaryBlockItem {
  id: string
  text: string
}
export interface BulletSummaryBlock extends SummaryTemplateField {
  id: string
  blockType: "bullet"
  items: SummaryBlockItem[]
}

export interface SummaryBlockActionItem extends SummaryBlockItem {
  completed: boolean
}

export interface ActionItemSummaryBlock extends SummaryTemplateField {
  id: string
  blockType: "actionItems"
  items: SummaryBlockActionItem[]
}

export type SummaryBlock = ActionItemSummaryBlock | BulletSummaryBlock

export type SummaryBlockInProgress = SummaryBlock & {
  completed: boolean
}

export type CoachingMetrics = {
  talkPercentages?: {}
  averageSentenceLengths?: {}
  longestMonologues?: {}
  questions?: string
}

export interface SysSummary {
  id: string
  createdAt: string | number // use Date.now(), string allowed for legacy
  displaySummary?: string // legacy, delete in next migration
  blockSummary: SummaryBlock[]
  fullPrompt?: string | string[] // The full prompt(s) sent to LLM
  LLMResString?: string | string[] // The raw string(s) from LLM
  templateUsed?: SummaryTemplate | PrepTemplate
  meeting: Meeting
  meetingRef?: Meeting["id"] // For easy search, can't search inside nested objects in firebase
  summary?: any // legacy, delete in next migration
}

// ACCOUNTS AND DEALS

// collection: "accounts"
export interface Account {
  id: string
  name: string
  ownerRefs: UserData["id"][]
  teamRefs: Team["id"][]
  contactRefs: Contact["id"][]
  crmId?: string
  crmName?: Source
}

type Source = "salesforce" | "internal" | "hubspot"

// collection: "deals"
export interface Deal {
  id: string
  name: string
  description?: string
  amount: number
  nextStep?: string
  closeDate: number
  stage?: string
  ownerRefs: UserData["id"][]
  meetingRefs?: Meeting["id"][]
  accountRef?: string
  crmId?: string
  crmName?: Source
}

// collection: "contacts"
export interface Contact {
  id: string
  name: string
  firstName: string
  lastName: string
  email?: string
  ownerRefs: UserData["id"][]
  accountRefs: Account["id"][]
  crmId?: string
  crmName?: Source
}

// SUBSCRIPTIONS

export interface Plan {
  name: License
  quota: number
  price: number
  monthlyQuotaRenew: boolean
}

// QUOTA CONTROL

export type GPT4Model = "gpt-4o-mini" | "gpt-4-turbo" | "gpt-4o-2024-08-06"

export type ModelQuota = {
  name: GPT4Model
  tokenQuotaPerMinute: number
  maxTokensPerRequest: number
}

export enum FeatureFlagKey {
  DealSummary = "deal_summary",
  SalesforceIntegration = "salesforce_integration",
  ZoomIntegration = "zoom_integration",
  CRMTemplates = "crm_templates",
  CRMMapping = "crm_mapping",
  HomePage = "home_page",
  HubspotIntegration = "hubspot_integration",
  ExtendedFilters = "extended_filters",
  FollowupEmail = "followup_email",
  botJoinSettings = "bot_join_settings",
  Workflows = "workflows",
  Salesloft = "salesloft",
  Filters = "filters",
  runWorkflowByCalls = "workflow_run_by_calls",
  isEnrichActive = "is_enrich_active",
  OutboundMessage = "outbound_message",
}

// crmMappings
export interface CrmMapping {
  id: string
  accountMapping: any
  contactMapping: any
}

// collection: "products"

export interface Product {
  id: string
  name: string
  description: string
  ownerRefs: UserData["id"][]
}

export type SalesforceField =
  | {
      name: string
      type: "picklist" | "multipicklist"
      label: string
      picklistValues: {
        active: boolean
        validFor?: string | null
        defaultValue: boolean
        label?: string | null
        value: string
      }[]
    }
  | {
      name: string
      type: "string" | "textarea" | "email" | "phone"
      label: string
      length: number
    }
  | {
      name: string
      type: "time" | "date"
      label: string
    }
  | {
      name: string
      type: "double" | "int" | "currency"
      label: string
      precision: number
      scale: number
    }

export type HubspotField =
  | {
      name: string
      type: "enumeration"
      label: string
      fieldType: "radio" | "select" | "booleancheckbox" | "checkbox"
      options: {
        label?: string | null
        value: string
        hidden: boolean
        displayOrder: number
        description?: string | null
      }[]
    }
  | {
      name: string
      type: "string"
      label: string
      fieldType: "text" | "textarea" | "phonenumber"
      length: number
    }
  | {
      name: string
      type: "date"
      fieldType: "date"
      label: string
    }
  | {
      name: string
      type: "number"
      fieldType: "number"
      label: string
    }

export type EnrichLookup = CompanyEnrichLookup | PersonEnrichLookup

export interface CompanyEnrichLookup {
  id: string
  lookupParam: EnrichCompanyLookupParam
  type: EnrichLookupType.COMPANY_LOOKUP
  ownerRefs: UserData["id"][]
  created?: number
  updated?: number
  raw: CompanyRawData
}

export interface PersonEnrichLookup {
  id: string
  lookupParam: string
  type: EnrichLookupType.PERSONAL_LOOKUP
  ownerRefs: UserData["id"][]
  created?: number
  updated?: number
  raw: PersonRawData
}

export interface PersonRawData {
  error?: boolean
  message?: string
  firstName?: string
  lastName?: string
  email?: string
  location?: string
  positions?: {
    positionHistory?: {
      title?: string
      companyName?: string
      companyLogo?: string
      linkedInUrl?: string
      startEndDate?: {
        end?: {
          month?: number | null
          year?: number | null
        }
        start?: {
          month?: number
          year: number
        }
      }
    }[]
  }
  schools?: {
    educationHistory?: {
      degreeName?: string
      fieldOfStudy?: string
      linkedInUrl?: string
      schoolLogo?: string
      schoolName?: string
      startEndDate?: {
        end?: {
          year?: number | null
        }
        start?: {
          year?: number
        }
      }
    }[]
  }
  photoUrl?: string
  linkedInUrl?: string
  skills?: string[]
}

export interface CompanyRawData {
  error?: boolean
  message?: string
  name?: string
  images?: {
    logo?: string
  }
  urls?: {
    company_page?: string
    li_url?: string
  }
  staff?: {
    range?: {
      start?: number
      end?: number
    }
  }
  industries?: string[]
}

export type EnrichCompanyLookupParam = {
  companyName: string
  companyDomain: string
}

export enum EnrichLookupType {
  PERSONAL_LOOKUP = "person",
  COMPANY_LOOKUP = "company",
}

export type RecallLanguageCode =
  | "en-US"
  | "en-GB"
  | "fr-FR"
  | "de-DE"
  | "es-US"
  | "pt-BR"
  | "it-IT"
  | "da-DK"
  | "et-ET"
  | "nl-NL"
  | "fi-FI"
  | "pl-PL"
  | "sv-SE"
  | "ro-RO"
  | "no-NO"
  | "ar-SA"
  | "zh-CN"
  | "cs-CZ"
  | "he-IL"
  | "hi-IN"
  | "hu-HU"
  | "id-ID"
  | "ja-JP"
  | "ko-KR"
  | "ms-MY"
  | "ru-RU"
  | "tl-PH"
  | "ta-IN"
  | "te-IN"
  | "th-TH"
  | "tr-TR"
  | "uk-UA"
  | "vi-VN"
  | "el-GR"
  | "sk-SK"
  | "cy-WL"

export type CombinedLanguageCode = LanguageCode | RecallLanguageCode

export * from "./crm"
export * from "./dto/invite"
export * from "./workflow"
